.root {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  transition: all 0.4s ease-in-out;

  button {
    cursor: pointer;
  }

  .searchButton {
    display: none;

    @media (max-width: 991px) {
      display: inline-block !important;
      border: 0;
      margin-right: 12px;
      width: 32px;
      padding: 0;

      &:hover,
      &:focus {
        background: transparent;
        border: 0;
      }
    }

    img {
      max-width: 24px;
      margin-left: 8px;
    }

    span {
      &:first-of-type {
        color: #ee2943;
        margin-right: 8px;
      }
    }
  }

  :global {
    .container {
      max-width: 1920px;
      padding: 0 42px;

      @media (max-width: 768px) {
        padding: 0 24px;
      }
    }
  }

  &.hide {
    z-index: 2;
    background: #00013a;
    top: -130px;

    .loginButton {
      display: none;
    }

    .stickySearchImage {
      width: 16px;
    }

    .searchButton {
      display: inline-block;

      &:hover {
        background: #00013a;
        color: #fff;
      }

      @media (max-width: 480px) {
        border: 0;
        padding: 0;
        height: auto;
      }
    }
  }

  &.fixed {
    position: fixed;
    top: 0;

    .sideMenu {
      .loginButton {
        display: block;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;

    @media (max-width: 991px) {
      padding: 12px 0;
    }

    .actions {
      display: flex;
      align-items: center;
    }

    .logo {
      img {
        max-width: 105px;
      }
    }

    button {
      height: 42px;
      cursor: pointer;
    }
  }
}

.menuOpenerButton {
  background: transparent;
  height: 42px;
  width: 42px;
  border: 0;
  margin-left: 12px;
  background-color: #00013a;

  @media (max-width: 991px) {
    background-color: transparent;
    display: inline-block !important;
    border: 0;
    width: 32px;
    padding: 0;

    &:hover,
    &:focus {
      background: transparent;
      border: 0;
    }
  }

  &:focus {
    outline: none;
  }
}

.sideMenu {
  position: fixed;
  right: -300px;
  bottom: 0;
  top: 0;
  background: #00013a;
  width: 300px;
  transition: all 0.2s ease-in-out;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;

  .loginButton {
    display: inline-block !important;
  }

  .actions {
    display: flex;
    justify-content: space-between;

    .menuOpenerButton {
      width: 40px;
      height: 40px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul {
    li {
      margin-bottom: 4px;

      a {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 4px;
        display: inline-block;

        &:hover {
          color: #ff0033;
        }

        &.active {
          &:after {
            content: "";
            display: block;
            height: 2px;
            background-color: #ff0033;
          }
        }
      }
    }

    .logout {
      margin-top: 32px;
      margin-bottom: 16px;
      a {
        color: #ff0033;
        font-size: 10px;
        line-height: 1.5;
        letter-spacing: 0.3px;
        margin: 0;
        border-bottom: 1px solid #ff0033;
        padding-bottom: 4px;
      }
    }
  }

  .langButton {
    height: 36px;
    width: 36px;
    border: 0;
    background: none;
    margin: 0 0 36px;
    padding: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/language-white.svg");

    &:hover {
      background-image: url("https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/language.svg");
    }
  }

  .bottom {
    ul {
      li {
        margin: 0;

        a {
          font-size: 10px;
          line-height: 1.5;
          letter-spacing: 0.3px;
          color: #ffffff;
          margin: 0;

          &:hover {
            color: #ff0033;
          }
        }
      }
    }
  }
}

.sideMenu.open {
  right: 0;
}

.helloUserName {
  padding: 0 0 24px;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
}
