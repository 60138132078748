.primary {
  border: solid 1.2px #00013a;
  color: #00013a;
  background-color: #00013a;
  background: none;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.6px;
  border-radius: 0;
  padding: 0 16px;
  height: 42px;

  &:focus,
  &:active,
  &:hover {
    background-color: #00013a;
    color: #fff;
    border: solid 1.2px #00013a;
  }
}

.red {
  border: solid 1.2px #ee2943;
  color: #ee2943;
  background-color: transparent;

  &:focus,
  &:active,
  &:hover {
    background-color: #ee2943;
    border: solid 1.2px #ee2943;
    color: #fff;
  }
}

.white {
  border: solid 1.2px white;
  color: white;
  background-color: transparent;

  &:focus,
  &:active,
  &:hover {
    background-color: white;
    border: solid 1.2px white;
    color: #00013a;
  }
}
