.blog {
  margin-bottom: 36px;
  a {
    display: block;
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #00013a;
    text-transform: uppercase;
    line-height: 26px;
    margin: 18px 0 6px;

    span {
      font-weight: bolder;
      -webkit-text-stroke-width: 0.8px;
      -webkit-text-stroke-color: #00013a;
      -webkit-text-stroke: 0.8px #00013a;
      -moz-text-stroke: 0.8px #00013a;
      -ms-text-stroke: 0.8px #00013a;
      -o-text-stroke: 0.8px #00013a;
      color: transparent;
    }
  }

  p {
    font-family: "Noto Serif", serif;
    font-style: italic;
    color: #00013a;
    font-size: 14px;
    margin-bottom: 16px;
  }
}
