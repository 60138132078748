.root {
  width: 100%;
  top: 0;
  padding-bottom: 0;
  max-width: 100%;
  margin: 0;

  :global {
    .ant-modal-content {
      border-radius: 0;
      height: 100%;
      min-height: 100vh;
    }

    .ant-modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100vh;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 24px 16px 0;
  }
  padding: 24px 30px 0;

  .logo {
    cursor: pointer;
    img {
      max-width: 105px;
    }
  }

  .closeButton {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;

    img {
      max-width: 20px;
    }
  }
}

.emptyView {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 960px;
  padding: 0 55px;

  strong{
    color: #00013a;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

.searcWrapper {
  padding: 0 55px;
  text-align: center;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
}

.field {
  margin-top: 32px;
  margin-bottom: 32px;

  input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e1e0dc;
    color: #00013a;
    text-align: center;
    height: 42px;
    padding-bottom: 24px;

    &:focus {
      outline: none;
    }
  }
}

.searchResults {
  margin-bottom: 32px;

  h3 {
    font-weight: 500;
    letter-spacing: 3px;
    color: #00013a;
    text-transform: uppercase;
    margin-bottom: 12px;
    font-size: 14px;
  }

  ul {
    li {
      button {
        border: 0;
        background-color: transparent;
        padding: 0;
        margin: 0 auto 4px;
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 2.4px;
        text-align: center;
        color: #00013a;
        display: block;
        margin: 0 auto 8px;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
  }
}

.categories {
  display: flex;
  overflow: hidden;

  @media (max-width: 991px) {
    display: block;
  }

  a {
    position: relative;

    @media (max-width: 991px) {
      display: block;
      background: rgb(21, 15, 69);

      &:nth-child(even) {
        background: #00013a;
      }

      img {
        display: none;
      }
    }
    &:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transition: all 0.2s ease-out;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &:nth-child(odd)::after {
      background: rgb(21, 15, 69);
    }

    &:nth-child(even)::after {
      background: #00013a;
    }

    &:hover {
      &:after {
        background: rgba(21, 15, 69, 0);
      }
    }

    .category {
      position: relative;
      display: block;

      @media (max-width: 991px) {
        padding: 24px 16px;
        text-align: center;
      }
    }

    h3 {
      font-weight: bolder;
      -webkit-text-stroke-width: 0.8px;
      -webkit-text-stroke: 0.8px hsla(0, 0%, 100%, 0.8);
      -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      -moz-text-stroke: 0.8px hsla(0, 0%, 100%, 0.8);
      -ms-text-stroke: 0.8px hsla(0, 0%, 100%, 0.8);
      -o-text-stroke: 0.8px hsla(0, 0%, 100%, 0.8);
      color: transparent;
      font-family: Poppins;
      letter-spacing: 0.3px;
      position: absolute;
      margin: auto;
      top: 50%;
      left: 50%;
      z-index: 2;
      line-height: 0px;
      text-transform: uppercase;
      font-size: 32px;
      letter-spacing: 8px;

      @media (max-width: 991px) {
        -webkit-text-stroke: none;
        -moz-text-stroke: none;
        -ms-text-stroke: none;
        -o-text-stroke: none;
        transform: rotate(0deg);
        position: static;
        letter-spacing: 3px;
        line-height: 40px;
        left: 0;
      }
    }
  }
}

.resultEvents {
  padding-bottom: 80px;

  .row {
    display: flex;
    flex-wrap: wrap;

    .column {
      width: 33%;
      padding: 0 16px;
      margin-bottom: 35px;

      @media (max-width: 991px) {
        width: 50%;
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
}
