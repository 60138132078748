.root {
  :global {
    .container {
      position: relative;

      i {
      }

      .left {
      }

      .slick-arrow {
        border: solid #01013a;
        border-width: 0 5px 5px 0;
        display: inline-block;
        padding: 5px;
        z-index: 12;
        top: 32%;
      }

      .slick-arrow.slick-prev {
        left: 8px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }

      .slick-arrow.slick-next {
        right: 18px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      .slick-disabled {
        opacity: 0.3;
      }
    }
  }
}

.mobileBg {
  h1,
  h2 {
    font-size: 56px;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
    margin-bottom: 15px;

    @media (max-width: 991px) {
      font-size: 29px;
      line-height: 36px;
      margin: 0;
      padding: 16px 16px;
      border-top: 1px solid #363665;
      border-bottom: 1px solid #363665;
    }
  }

  h2 {
    -webkit-text-stroke: 1px #ffffff;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: left;
    color: transparent;

    @media (max-width: 991px) {
      border-top: 0;
    }
  }
  .mobilePartial {
    position: relative;
    display: flex;
    justify-content: center;
    max-height: 136px;

    h1,
    h2 {
      position: absolute;
      z-index: 1;
      border: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 74px;
      font-size: 23px;
      padding-top: 0;
      padding-bottom: 0;
      img {
        max-height: 300px;
      }
    }
  }
}

.banner {
  position: relative;
  padding-bottom: 46px;

  @media (max-width: 991px) {
    padding-bottom: 0;
    margin-bottom: 16px;
  }

  video {
    width: 100%;
  }

  .imageBg {
    background: #00013a;
  }

  .bannerContent {
    font-family: "Be Vietnam", sans-serif;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 991px) {
      justify-content: space-around;
    }

    :global {
      .container {
        width: 100%;
        position: static;
        padding: 0 42px;
        @media (max-width: 991px) {
          padding: 24px;
          margin-top: 46px;
        }
      }
    }

    h1,
    h2 {
      font-size: 56px;
      font-weight: medium;
      color: #ffffff;
      line-height: 56px;
      margin-bottom: 15px;

      @media (max-width: 991px) {
        font-size: 42px;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
      }

      @media (max-width: 768px) {
        font-size: 34px;
        line-height: 38px;
        margin: 12px 0 0 0;
      }
    }

    h2 {
      -webkit-text-stroke: 1px #ffffff;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: left;
      color: transparent;

      @media (max-width: 991px) {
        border-top: 0;
      }
    }

    button {
      background-color: #ffffff;
      padding: 0 26px;
      margin-top: 14px;

      @media (max-width: 991px) {
        margin: 0 32px;
        display: none;
      }

      img {
        margin-left: 16px;
        max-width: 16px;
      }

      span {
        color: #00013a;

        &:first-of-type {
          color: #ee2943;
          margin-right: 4px;
        }
      }
    }
  }
}

.placesWrapper {
  :global {
    .slick-arrow {
      top: 50% !important;
      margin-top: -15px !important;
    }
  }
}

.intro,
.populerEvents,
.placesWrapper,
.specialEvents {
  margin-bottom: 46px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
}

.place {
  display: block;
  position: relative;

  h2 {
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 18px;
    text-align: center;
    margin: 0 auto;
  }
}

.intro {
  background-color: #00013a;
  font-size: 24px;
  color: #ffffff;
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: block;
    text-align: center;
  }

  > span {
    color: #ee2943;
    margin: 0 6px;
  }

  button {
    margin-left: 40px;

    @media (max-width: 768px) {
      display: block;
      margin: 16px auto 0;
    }
  }
}

.dailyPopular{
  p {
    font-family: "Noto Serif", serif;
    font-style: italic;
    color: #00013a;
    font-size: 14px;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #00013a;
    text-transform: uppercase;
    line-height: 26px;
    margin: 18px 0 6px;

    span {
      font-weight: bolder;
      -webkit-text-stroke-width: 0.8px;
      -webkit-text-stroke-color: #00013a;
      -webkit-text-stroke: 0.8px #00013a;
      -moz-text-stroke: 0.8px #00013a;
      -ms-text-stroke: 0.8px #00013a;
      -o-text-stroke: 0.8px #00013a;
      color: transparent;
    }
  }
}

.moreButton {
  display: block;
  margin: 0px auto 48px;
  @media (max-width: 768px) {
    margin: 0px auto 16px;
  }
}

.vertical {
  position: absolute;
  top: 0;
  left: 33px;
  margin: 0 auto;

  @media (max-width: 768px) {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #00013a;
    -webkit-text-stroke: 0.8px #00013a;
    -moz-text-stroke: 0.8px #00013a;
    -ms-text-stroke: 0.8px #00013a;
    -o-text-stroke: 0.8px #00013a;
    color: transparent;
    font-size: 36px;
    font-family: Poppins;
    letter-spacing: 2px;
    font-weight: bolder;
    margin-bottom: 8px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    position: static;
  }
  img {
    width: 60px;
  }
}

.mobileBox {
  max-width: 480px;
  @media (max-width: 768px) {
    padding: 0 0px 0 16px;
  }

  @media (max-width: 520px) {
    max-width: 380px;
  }

  @media (max-width: 460px) {
    max-width: 320px;
  }

  @media (max-width: 340px) {
    max-width: 300px;
  }
}

.placesWrapper,
.populerEvents,
.specialEvents {
  :global {
    .container {
      @media (max-width: 768px) {
        padding-right: 0;
        padding-left: 0;
        h3 {
          margin-left: 16px;
        }
      }
    }
  }
}

.loader {
  margin: 0px auto 24px;
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #ee2943;
  top: 50%;
  animation: loader 2s infinite ease;
}

.loaderInner {
  display: block;
  width: 100%;
  background-color: #150f45;
  animation: loaderInner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loaderInner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
