.root {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 1, 58, 0.9);
  z-index: 2;
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
  }

  // >div {
  //   padding: 0 42px;
  // }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    @media (max-width: 768px) {
      display: block;
    }

    .actions {
      display: flex;
    }

    button {
      border: 0;
      height: 60px;
      background: #ee2943;
    }

    a {
      height: 60px;
      line-height: 60px;
      display: block;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.6px;
      border-radius: 0;
      padding: 0 16px;
      color: #fff;
      background-color: rgba(21, 15, 69, 1);
      text-align: center;

      &:hover {
        color: #ee2943;
      }
    }

    p {
      margin: 0;
      padding: 4px 16px;
    }

    @media (max-width: 768px) {
      p {
        padding: 12px 16px;
      }
      a,
      button {
        width: 50%;
        height: 42px;
        line-height: 42px;
      }
    }
  }
}
