@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif:400,400i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Be+Vietnam:400,500,600&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1440px;
  padding: 0 120px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    padding: 0 16px;
  }
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button:focus {
  outline: none;
}

.ant-btn-primary {
  text-shadow: none;
}
.ant-dropdown-menu {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-dropdown-menu {
  border-radius: 0px;
  padding: 0px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  text-align: center;
  color: #01013a;
  font-size: 11px;
  padding: 6px;
  line-height: 24px;
  letter-spacing: 1.1px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #01013a;
  color: #fff;
}

::-webkit-input-placeholder {
  /* Edge */
  color: gray;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray;
}

::placeholder {
  color: gray;
}

.loader {
  margin: 0px auto 24px;
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #ee2943;
  top: 50%;
  animation: loader 2s infinite ease;
}

.loader-inner {
  display: block;
  width: 100%;
  background-color: #150f45;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.app-loader {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.ant-carousel {
  margin: 0 0 12px 0;
}