.root {
  position: relative;
  display: flex;

  :global {
    .container {
      max-width: 1920px;
      padding: 0 42px;
      width: 100%;
      display: flex;

      @media (max-width: 768px) {
        display: block;
        padding: 0;
      }
    }
  }

  ul {
    position: relative;
    z-index: 1;

    li {
      a {
        letter-spacing: 1.92px;
        color: #ffffff;
      }
    }
  }

  .left {
    width: 50%;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      background: #01013a;
      right: 50%;
      top: 0;
      bottom: 0;
      left: 0;
      @media (max-width: 768px) {
        right: 0;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        padding-right: 16px;

        a {
          font-size: 11px;
          letter-spacing: 1.92px;
          color: #ffffff;
          text-transform: uppercase;
          @media (max-width: 400px) {
            padding: 4px 0;
            display: block;
          }

          &:hover {
            color: #ff0032;
          }
        }
      }
    }
  }

  .right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: "";
      position: absolute;
      background: #ff0032;
      left: 50%;
      top: 0;
      bottom: 0;
      right: 0;

      @media (max-width: 768px) {
        left: 0;
      }
    }

    ul {
      padding: 16px 48px;
      li {
        line-height: 1;
        a {
          font-size: 9px;
          letter-spacing: 0.28px;
          &:hover {
            color: #01013a;
          }
        }
      }
    }
  }

  .left,
  .right {
    padding: 0 16px;
    @media (max-width: 768px) {
      width: 100%;
      position: relative;
      min-height: 100px;

      ul {
        padding: 0;
      }
    }
  }
}

.langButton {
  height: 34px;
  width: 36px;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/language-white.svg");
  position: relative;
  z-index: 1;
}
