@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,400i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Be+Vietnam:400,500,600&display=swap);
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1440px;
  padding: 0 120px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    padding: 0 16px;
  }
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button:focus {
  outline: none;
}

.ant-btn-primary {
  text-shadow: none;
}
.ant-dropdown-menu {
  box-shadow: none !important;
}

.ant-dropdown-menu {
  border-radius: 0px;
  padding: 0px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  text-align: center;
  color: #01013a;
  font-size: 11px;
  padding: 6px;
  line-height: 24px;
  letter-spacing: 1.1px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #01013a;
  color: #fff;
}

::-webkit-input-placeholder {
  /* Edge */
  color: gray;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray;
}

::-moz-placeholder {
  color: gray;
}

::-ms-input-placeholder {
  color: gray;
}

::placeholder {
  color: gray;
}

.loader {
  margin: 0px auto 24px;
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #ee2943;
  top: 50%;
  -webkit-animation: loader 2s infinite ease;
          animation: loader 2s infinite ease;
}

.loader-inner {
  display: block;
  width: 100%;
  background-color: #150f45;
  -webkit-animation: loader-inner 2s infinite ease-in;
          animation: loader-inner 2s infinite ease-in;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.app-loader {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.ant-carousel {
  margin: 0 0 12px 0;
}
.Header_root__1UKjN {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  .Header_root__1UKjN button {
    cursor: pointer; }
  .Header_root__1UKjN .Header_searchButton__3ej17 {
    display: none; }
    @media (max-width: 991px) {
      .Header_root__1UKjN .Header_searchButton__3ej17 {
        display: inline-block !important;
        border: 0;
        margin-right: 12px;
        width: 32px;
        padding: 0; }
        .Header_root__1UKjN .Header_searchButton__3ej17:hover, .Header_root__1UKjN .Header_searchButton__3ej17:focus {
          background: transparent;
          border: 0; } }
    .Header_root__1UKjN .Header_searchButton__3ej17 img {
      max-width: 24px;
      margin-left: 8px; }
    .Header_root__1UKjN .Header_searchButton__3ej17 span:first-of-type {
      color: #ee2943;
      margin-right: 8px; }
  .Header_root__1UKjN .container {
    max-width: 1920px;
    padding: 0 42px; }
    @media (max-width: 768px) {
      .Header_root__1UKjN .container {
        padding: 0 24px; } }
  .Header_root__1UKjN.Header_hide__13FTx {
    z-index: 2;
    background: #00013a;
    top: -130px; }
    .Header_root__1UKjN.Header_hide__13FTx .Header_loginButton__368ik {
      display: none; }
    .Header_root__1UKjN.Header_hide__13FTx .Header_stickySearchImage__2IBoy {
      width: 16px; }
    .Header_root__1UKjN.Header_hide__13FTx .Header_searchButton__3ej17 {
      display: inline-block; }
      .Header_root__1UKjN.Header_hide__13FTx .Header_searchButton__3ej17:hover {
        background: #00013a;
        color: #fff; }
      @media (max-width: 480px) {
        .Header_root__1UKjN.Header_hide__13FTx .Header_searchButton__3ej17 {
          border: 0;
          padding: 0;
          height: auto; } }
  .Header_root__1UKjN.Header_fixed__3TQHb {
    position: fixed;
    top: 0; }
    .Header_root__1UKjN.Header_fixed__3TQHb .Header_sideMenu__2_H3v .Header_loginButton__368ik {
      display: block; }
  .Header_root__1UKjN .Header_content__2lN0V {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0; }
    @media (max-width: 991px) {
      .Header_root__1UKjN .Header_content__2lN0V {
        padding: 12px 0; } }
    .Header_root__1UKjN .Header_content__2lN0V .Header_actions__319O2 {
      display: flex;
      align-items: center; }
    .Header_root__1UKjN .Header_content__2lN0V .Header_logo__1xAwe img {
      max-width: 105px; }
    .Header_root__1UKjN .Header_content__2lN0V button {
      height: 42px;
      cursor: pointer; }

.Header_menuOpenerButton__1QP8- {
  background: transparent;
  height: 42px;
  width: 42px;
  border: 0;
  margin-left: 12px;
  background-color: #00013a; }
  @media (max-width: 991px) {
    .Header_menuOpenerButton__1QP8- {
      background-color: transparent;
      display: inline-block !important;
      border: 0;
      width: 32px;
      padding: 0; }
      .Header_menuOpenerButton__1QP8-:hover, .Header_menuOpenerButton__1QP8-:focus {
        background: transparent;
        border: 0; } }
  .Header_menuOpenerButton__1QP8-:focus {
    outline: none; }

.Header_sideMenu__2_H3v {
  position: fixed;
  right: -300px;
  bottom: 0;
  top: 0;
  background: #00013a;
  width: 300px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3; }
  .Header_sideMenu__2_H3v .Header_loginButton__368ik {
    display: inline-block !important; }
  .Header_sideMenu__2_H3v .Header_actions__319O2 {
    display: flex;
    justify-content: space-between; }
    .Header_sideMenu__2_H3v .Header_actions__319O2 .Header_menuOpenerButton__1QP8- {
      width: 40px;
      height: 40px; }
  .Header_sideMenu__2_H3v ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .Header_sideMenu__2_H3v ul li {
    margin-bottom: 4px; }
    .Header_sideMenu__2_H3v ul li a {
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 4px;
      display: inline-block; }
      .Header_sideMenu__2_H3v ul li a:hover {
        color: #ff0033; }
      .Header_sideMenu__2_H3v ul li a.Header_active__1OPnb:after {
        content: "";
        display: block;
        height: 2px;
        background-color: #ff0033; }
  .Header_sideMenu__2_H3v ul .Header_logout__2AOvv {
    margin-top: 32px;
    margin-bottom: 16px; }
    .Header_sideMenu__2_H3v ul .Header_logout__2AOvv a {
      color: #ff0033;
      font-size: 10px;
      line-height: 1.5;
      letter-spacing: 0.3px;
      margin: 0;
      border-bottom: 1px solid #ff0033;
      padding-bottom: 4px; }
  .Header_sideMenu__2_H3v .Header_langButton__3JCo- {
    height: 36px;
    width: 36px;
    border: 0;
    background: none;
    margin: 0 0 36px;
    padding: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/language-white.svg"); }
    .Header_sideMenu__2_H3v .Header_langButton__3JCo-:hover {
      background-image: url("https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/language.svg"); }
  .Header_sideMenu__2_H3v .Header_bottom__1bKjy ul li {
    margin: 0; }
    .Header_sideMenu__2_H3v .Header_bottom__1bKjy ul li a {
      font-size: 10px;
      line-height: 1.5;
      letter-spacing: 0.3px;
      color: #ffffff;
      margin: 0; }
      .Header_sideMenu__2_H3v .Header_bottom__1bKjy ul li a:hover {
        color: #ff0033; }

.Header_sideMenu__2_H3v.Header_open__2PXl1 {
  right: 0; }

.Header_helloUserName__143CI {
  padding: 0 0 24px;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff; }

.Footer_root__25Dsq {
  position: relative;
  display: flex; }
  .Footer_root__25Dsq .container {
    max-width: 1920px;
    padding: 0 42px;
    width: 100%;
    display: flex; }
    @media (max-width: 768px) {
      .Footer_root__25Dsq .container {
        display: block;
        padding: 0; } }
  .Footer_root__25Dsq ul {
    position: relative;
    z-index: 1; }
    .Footer_root__25Dsq ul li a {
      letter-spacing: 1.92px;
      color: #ffffff; }
  .Footer_root__25Dsq .Footer_left__2POAH {
    width: 50%;
    display: flex;
    align-items: center; }
    .Footer_root__25Dsq .Footer_left__2POAH:before {
      content: "";
      position: absolute;
      background: #01013a;
      right: 50%;
      top: 0;
      bottom: 0;
      left: 0; }
      @media (max-width: 768px) {
        .Footer_root__25Dsq .Footer_left__2POAH:before {
          right: 0; } }
    .Footer_root__25Dsq .Footer_left__2POAH ul {
      display: flex;
      flex-wrap: wrap; }
      .Footer_root__25Dsq .Footer_left__2POAH ul li {
        padding-right: 16px; }
        .Footer_root__25Dsq .Footer_left__2POAH ul li a {
          font-size: 11px;
          letter-spacing: 1.92px;
          color: #ffffff;
          text-transform: uppercase; }
          @media (max-width: 400px) {
            .Footer_root__25Dsq .Footer_left__2POAH ul li a {
              padding: 4px 0;
              display: block; } }
          .Footer_root__25Dsq .Footer_left__2POAH ul li a:hover {
            color: #ff0032; }
  .Footer_root__25Dsq .Footer_right__EJiZv {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .Footer_root__25Dsq .Footer_right__EJiZv:after {
      content: "";
      position: absolute;
      background: #ff0032;
      left: 50%;
      top: 0;
      bottom: 0;
      right: 0; }
      @media (max-width: 768px) {
        .Footer_root__25Dsq .Footer_right__EJiZv:after {
          left: 0; } }
    .Footer_root__25Dsq .Footer_right__EJiZv ul {
      padding: 16px 48px; }
      .Footer_root__25Dsq .Footer_right__EJiZv ul li {
        line-height: 1; }
        .Footer_root__25Dsq .Footer_right__EJiZv ul li a {
          font-size: 9px;
          letter-spacing: 0.28px; }
          .Footer_root__25Dsq .Footer_right__EJiZv ul li a:hover {
            color: #01013a; }
  .Footer_root__25Dsq .Footer_left__2POAH,
  .Footer_root__25Dsq .Footer_right__EJiZv {
    padding: 0 16px; }
    @media (max-width: 768px) {
      .Footer_root__25Dsq .Footer_left__2POAH,
      .Footer_root__25Dsq .Footer_right__EJiZv {
        width: 100%;
        position: relative;
        min-height: 100px; }
        .Footer_root__25Dsq .Footer_left__2POAH ul,
        .Footer_root__25Dsq .Footer_right__EJiZv ul {
          padding: 0; } }

.Footer_langButton__5xEJ8 {
  height: 34px;
  width: 36px;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/language-white.svg");
  position: relative;
  z-index: 1; }

.EventItem_root__1LZeU.EventItem_withMargin__2U_-V {
  margin-bottom: 32px; }

.EventItem_root__1LZeU {
  position: relative; }
  .EventItem_root__1LZeU a {
    display: block; }
    .EventItem_root__1LZeU a:last-of-type {
      display: flex;
      align-items: flex-end; }
  .EventItem_root__1LZeU img {
    max-width: 100%; }
  .EventItem_root__1LZeU h1 {
    font-size: 19px;
    font-weight: 500;
    color: #00013a;
    margin: 24px 0 10px;
    line-height: 24px; }
  .EventItem_root__1LZeU .EventItem_place__12_dK,
  .EventItem_root__1LZeU .EventItem_date__38Ovs {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.9px;
    color: #00013a; }
  .EventItem_root__1LZeU .EventItem_online__N8Nrm {
    background-color: #ffffff;
    position: absolute;
    right: 24px;
    top: 23px; }
    .EventItem_root__1LZeU .EventItem_online__N8Nrm .EventItem_title__C6N3E {
      padding: 5px 12px 4px 12px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.9px;
      text-align: left;
      color: #00013a; }
  .EventItem_root__1LZeU .EventItem_bottom__33CQ7 {
    margin-top: 16px;
    display: flex; }
  .EventItem_root__1LZeU .EventItem_buyButton__21wRR {
    margin-left: 16px; }
  .EventItem_root__1LZeU .EventItem_priceWrapper__1YiKF {
    display: flex;
    align-items: flex-end; }
  .EventItem_root__1LZeU button {
    text-transform: uppercase;
    font-size: 10px; }
  .EventItem_root__1LZeU .EventItem_price___e_F7 {
    margin-left: 16px; }
    .EventItem_root__1LZeU .EventItem_price___e_F7 strong {
      font-size: 16px;
      font-weight: 500;
      color: #00013a;
      line-height: 1.31; }
    .EventItem_root__1LZeU .EventItem_price___e_F7 span {
      font-size: 10px;
      line-height: 1.31;
      color: #00013a; }
    .EventItem_root__1LZeU .EventItem_price___e_F7 > span {
      display: block; }

.Button_primary__Ys8sl {
  border: solid 1.2px #00013a;
  color: #00013a;
  background-color: #00013a;
  background: none;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.6px;
  border-radius: 0;
  padding: 0 16px;
  height: 42px; }
  .Button_primary__Ys8sl:focus, .Button_primary__Ys8sl:active, .Button_primary__Ys8sl:hover {
    background-color: #00013a;
    color: #fff;
    border: solid 1.2px #00013a; }

.Button_red__1F8wb {
  border: solid 1.2px #ee2943;
  color: #ee2943;
  background-color: transparent; }
  .Button_red__1F8wb:focus, .Button_red__1F8wb:active, .Button_red__1F8wb:hover {
    background-color: #ee2943;
    border: solid 1.2px #ee2943;
    color: #fff; }

.Button_white__2HrKB {
  border: solid 1.2px white;
  color: white;
  background-color: transparent; }
  .Button_white__2HrKB:focus, .Button_white__2HrKB:active, .Button_white__2HrKB:hover {
    background-color: white;
    border: solid 1.2px white;
    color: #00013a; }

.BlogPost_blog__mAkHH {
  margin-bottom: 36px; }
  .BlogPost_blog__mAkHH a {
    display: block; }
  .BlogPost_blog__mAkHH h1 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #00013a;
    text-transform: uppercase;
    line-height: 26px;
    margin: 18px 0 6px; }
    .BlogPost_blog__mAkHH h1 span {
      font-weight: bolder;
      -webkit-text-stroke-width: 0.8px;
      -webkit-text-stroke-color: #00013a;
      -webkit-text-stroke: 0.8px #00013a;
      -moz-text-stroke: 0.8px #00013a;
      -ms-text-stroke: 0.8px #00013a;
      -o-text-stroke: 0.8px #00013a;
      color: transparent; }
  .BlogPost_blog__mAkHH p {
    font-family: "Noto Serif", serif;
    font-style: italic;
    color: #00013a;
    font-size: 14px;
    margin-bottom: 16px; }

.Search_root__3ukxb {
  width: 100%;
  top: 0;
  padding-bottom: 0;
  max-width: 100%;
  margin: 0; }
  .Search_root__3ukxb .ant-modal-content {
    border-radius: 0;
    height: 100%;
    min-height: 100vh; }
  .Search_root__3ukxb .ant-modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh; }

.Search_header__3iqcd {
  display: flex;
  justify-content: space-between;
  padding: 24px 30px 0; }
  @media (max-width: 768px) {
    .Search_header__3iqcd {
      padding: 24px 16px 0; } }
  .Search_header__3iqcd .Search_logo__I6D8P {
    cursor: pointer; }
    .Search_header__3iqcd .Search_logo__I6D8P img {
      max-width: 105px; }
  .Search_header__3iqcd .Search_closeButton__3f8sO {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer; }
    .Search_header__3iqcd .Search_closeButton__3f8sO img {
      max-width: 20px; }

.Search_emptyView__YWBRM {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 960px;
  padding: 0 55px; }
  .Search_emptyView__YWBRM strong {
    color: #00013a;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase; }

.Search_searcWrapper__3PiMk {
  padding: 0 55px;
  text-align: center;
  max-width: 960px;
  margin: 0 auto;
  width: 100%; }

.Search_field__2g70Y {
  margin-top: 32px;
  margin-bottom: 32px; }
  .Search_field__2g70Y input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e1e0dc;
    color: #00013a;
    text-align: center;
    height: 42px;
    padding-bottom: 24px; }
    .Search_field__2g70Y input:focus {
      outline: none; }

.Search_searchResults__2NsEW {
  margin-bottom: 32px; }
  .Search_searchResults__2NsEW h3 {
    font-weight: 500;
    letter-spacing: 3px;
    color: #00013a;
    text-transform: uppercase;
    margin-bottom: 12px;
    font-size: 14px; }
  .Search_searchResults__2NsEW ul li button {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0 auto 4px;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 2.4px;
    text-align: center;
    color: #00013a;
    display: block;
    margin: 0 auto 8px;
    cursor: pointer;
    text-transform: uppercase; }

.Search_categories__33nVc {
  display: flex;
  overflow: hidden; }
  @media (max-width: 991px) {
    .Search_categories__33nVc {
      display: block; } }
  .Search_categories__33nVc a {
    position: relative; }
    @media (max-width: 991px) {
      .Search_categories__33nVc a {
        display: block;
        background: #150f45; }
        .Search_categories__33nVc a:nth-child(even) {
          background: #00013a; }
        .Search_categories__33nVc a img {
          display: none; } }
    .Search_categories__33nVc a:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out; }
      @media (max-width: 991px) {
        .Search_categories__33nVc a:after {
          display: none; } }
    .Search_categories__33nVc a:nth-child(odd)::after {
      background: #150f45; }
    .Search_categories__33nVc a:nth-child(even)::after {
      background: #00013a; }
    .Search_categories__33nVc a:hover:after {
      background: rgba(21, 15, 69, 0); }
    .Search_categories__33nVc a .Search_category__2J8b- {
      position: relative;
      display: block; }
      @media (max-width: 991px) {
        .Search_categories__33nVc a .Search_category__2J8b- {
          padding: 24px 16px;
          text-align: center; } }
    .Search_categories__33nVc a h3 {
      font-weight: bolder;
      -webkit-text-stroke-width: 0.8px;
      -webkit-text-stroke: 0.8px rgba(255, 255, 255, 0.8);
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      -moz-text-stroke: 0.8px rgba(255, 255, 255, 0.8);
      -ms-text-stroke: 0.8px rgba(255, 255, 255, 0.8);
      -o-text-stroke: 0.8px rgba(255, 255, 255, 0.8);
      color: transparent;
      font-family: Poppins;
      letter-spacing: 0.3px;
      position: absolute;
      margin: auto;
      top: 50%;
      left: 50%;
      z-index: 2;
      line-height: 0px;
      text-transform: uppercase;
      font-size: 32px;
      letter-spacing: 8px; }
      @media (max-width: 991px) {
        .Search_categories__33nVc a h3 {
          -webkit-text-stroke: none;
          -moz-text-stroke: none;
          -ms-text-stroke: none;
          -o-text-stroke: none;
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
          position: static;
          letter-spacing: 3px;
          line-height: 40px;
          left: 0; } }

.Search_resultEvents__3xUhI {
  padding-bottom: 80px; }
  .Search_resultEvents__3xUhI .Search_row__1Id9h {
    display: flex;
    flex-wrap: wrap; }
    .Search_resultEvents__3xUhI .Search_row__1Id9h .Search_column__2chPG {
      width: 33%;
      padding: 0 16px;
      margin-bottom: 35px; }
      @media (max-width: 991px) {
        .Search_resultEvents__3xUhI .Search_row__1Id9h .Search_column__2chPG {
          width: 50%; } }
      @media (max-width: 600px) {
        .Search_resultEvents__3xUhI .Search_row__1Id9h .Search_column__2chPG {
          width: 100%; } }

.AcceptCookies_root__2Sy6g {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 1, 58, 0.9);
  z-index: 2;
  width: 45%; }
  @media (max-width: 768px) {
    .AcceptCookies_root__2Sy6g {
      width: 100%; } }
  .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff; }
    @media (max-width: 768px) {
      .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N {
        display: block; } }
    .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N .AcceptCookies_actions__WwxHl {
      display: flex; }
    .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N button {
      border: 0;
      height: 60px;
      background: #ee2943; }
    .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N a {
      height: 60px;
      line-height: 60px;
      display: block;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.6px;
      border-radius: 0;
      padding: 0 16px;
      color: #fff;
      background-color: #150f45;
      text-align: center; }
      .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N a:hover {
        color: #ee2943; }
    .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N p {
      margin: 0;
      padding: 4px 16px; }
    @media (max-width: 768px) {
      .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N p {
        padding: 12px 16px; }
      .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N a,
      .AcceptCookies_root__2Sy6g .AcceptCookies_content__25b0N button {
        width: 50%;
        height: 42px;
        line-height: 42px; } }

.Home_root__JBfhQ .container {
  position: relative; }
  .Home_root__JBfhQ .container .slick-arrow {
    border: solid #01013a;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 5px;
    z-index: 12;
    top: 32%; }
  .Home_root__JBfhQ .container .slick-arrow.slick-prev {
    left: 8px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  .Home_root__JBfhQ .container .slick-arrow.slick-next {
    right: 18px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .Home_root__JBfhQ .container .slick-disabled {
    opacity: 0.3; }

.Home_mobileBg__3mw2P h1,
.Home_mobileBg__3mw2P h2 {
  font-size: 56px;
  font-weight: 500;
  color: #ffffff;
  line-height: 56px;
  margin-bottom: 15px; }
  @media (max-width: 991px) {
    .Home_mobileBg__3mw2P h1,
    .Home_mobileBg__3mw2P h2 {
      font-size: 29px;
      line-height: 36px;
      margin: 0;
      padding: 16px 16px;
      border-top: 1px solid #363665;
      border-bottom: 1px solid #363665; } }

.Home_mobileBg__3mw2P h2 {
  -webkit-text-stroke: 1px #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: left;
  color: transparent; }
  @media (max-width: 991px) {
    .Home_mobileBg__3mw2P h2 {
      border-top: 0; } }

.Home_mobileBg__3mw2P .Home_mobilePartial__nDL3P {
  position: relative;
  display: flex;
  justify-content: center;
  max-height: 136px; }
  .Home_mobileBg__3mw2P .Home_mobilePartial__nDL3P h1,
  .Home_mobileBg__3mw2P .Home_mobilePartial__nDL3P h2 {
    position: absolute;
    z-index: 1;
    border: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 74px;
    font-size: 23px;
    padding-top: 0;
    padding-bottom: 0; }
    .Home_mobileBg__3mw2P .Home_mobilePartial__nDL3P h1 img,
    .Home_mobileBg__3mw2P .Home_mobilePartial__nDL3P h2 img {
      max-height: 300px; }

.Home_banner__3njiV {
  position: relative;
  padding-bottom: 46px; }
  @media (max-width: 991px) {
    .Home_banner__3njiV {
      padding-bottom: 0;
      margin-bottom: 16px; } }
  .Home_banner__3njiV video {
    width: 100%; }
  .Home_banner__3njiV .Home_imageBg__yMIsf {
    background: #00013a; }
  .Home_banner__3njiV .Home_bannerContent__3A9PO {
    font-family: "Be Vietnam", sans-serif;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 991px) {
      .Home_banner__3njiV .Home_bannerContent__3A9PO {
        justify-content: space-around; } }
    .Home_banner__3njiV .Home_bannerContent__3A9PO .container {
      width: 100%;
      position: static;
      padding: 0 42px; }
      @media (max-width: 991px) {
        .Home_banner__3njiV .Home_bannerContent__3A9PO .container {
          padding: 24px;
          margin-top: 46px; } }
    .Home_banner__3njiV .Home_bannerContent__3A9PO h1,
    .Home_banner__3njiV .Home_bannerContent__3A9PO h2 {
      font-size: 56px;
      font-weight: medium;
      color: #ffffff;
      line-height: 56px;
      margin-bottom: 15px; }
      @media (max-width: 991px) {
        .Home_banner__3njiV .Home_bannerContent__3A9PO h1,
        .Home_banner__3njiV .Home_bannerContent__3A9PO h2 {
          font-size: 42px;
          font-weight: 500;
          color: #ffffff;
          line-height: 56px; } }
      @media (max-width: 768px) {
        .Home_banner__3njiV .Home_bannerContent__3A9PO h1,
        .Home_banner__3njiV .Home_bannerContent__3A9PO h2 {
          font-size: 34px;
          line-height: 38px;
          margin: 12px 0 0 0; } }
    .Home_banner__3njiV .Home_bannerContent__3A9PO h2 {
      -webkit-text-stroke: 1px #ffffff;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: left;
      color: transparent; }
      @media (max-width: 991px) {
        .Home_banner__3njiV .Home_bannerContent__3A9PO h2 {
          border-top: 0; } }
    .Home_banner__3njiV .Home_bannerContent__3A9PO button {
      background-color: #ffffff;
      padding: 0 26px;
      margin-top: 14px; }
      @media (max-width: 991px) {
        .Home_banner__3njiV .Home_bannerContent__3A9PO button {
          margin: 0 32px;
          display: none; } }
      .Home_banner__3njiV .Home_bannerContent__3A9PO button img {
        margin-left: 16px;
        max-width: 16px; }
      .Home_banner__3njiV .Home_bannerContent__3A9PO button span {
        color: #00013a; }
        .Home_banner__3njiV .Home_bannerContent__3A9PO button span:first-of-type {
          color: #ee2943;
          margin-right: 4px; }

.Home_placesWrapper__3RMBM .slick-arrow {
  top: 50% !important;
  margin-top: -15px !important; }

.Home_intro__2Mp5z,
.Home_populerEvents__2QnWF,
.Home_placesWrapper__3RMBM,
.Home_specialEvents__2ehCu {
  margin-bottom: 46px; }
  @media (max-width: 768px) {
    .Home_intro__2Mp5z,
    .Home_populerEvents__2QnWF,
    .Home_placesWrapper__3RMBM,
    .Home_specialEvents__2ehCu {
      margin-bottom: 16px; } }

.Home_place__38yRN {
  display: block;
  position: relative; }
  .Home_place__38yRN h2 {
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 18px;
    text-align: center;
    margin: 0 auto; }

.Home_intro__2Mp5z {
  background-color: #00013a;
  font-size: 24px;
  color: #ffffff;
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .Home_intro__2Mp5z {
      display: block;
      text-align: center; } }
  .Home_intro__2Mp5z > span {
    color: #ee2943;
    margin: 0 6px; }
  .Home_intro__2Mp5z button {
    margin-left: 40px; }
    @media (max-width: 768px) {
      .Home_intro__2Mp5z button {
        display: block;
        margin: 16px auto 0; } }

.Home_dailyPopular__27r0h p {
  font-family: "Noto Serif", serif;
  font-style: italic;
  color: #00013a;
  font-size: 14px;
  margin-bottom: 16px; }

.Home_dailyPopular__27r0h h1 {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #00013a;
  text-transform: uppercase;
  line-height: 26px;
  margin: 18px 0 6px; }
  .Home_dailyPopular__27r0h h1 span {
    font-weight: bolder;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #00013a;
    -webkit-text-stroke: 0.8px #00013a;
    -moz-text-stroke: 0.8px #00013a;
    -ms-text-stroke: 0.8px #00013a;
    -o-text-stroke: 0.8px #00013a;
    color: transparent; }

.Home_moreButton__1tyij {
  display: block;
  margin: 0px auto 48px; }
  @media (max-width: 768px) {
    .Home_moreButton__1tyij {
      margin: 0px auto 16px; } }

.Home_vertical__XvcUw {
  position: absolute;
  top: 0;
  left: 33px;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .Home_vertical__XvcUw {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      -webkit-text-stroke-width: 0.8px;
      -webkit-text-stroke-color: #00013a;
      -webkit-text-stroke: 0.8px #00013a;
      -moz-text-stroke: 0.8px #00013a;
      -ms-text-stroke: 0.8px #00013a;
      -o-text-stroke: 0.8px #00013a;
      color: transparent;
      font-size: 36px;
      font-family: Poppins;
      letter-spacing: 2px;
      font-weight: bolder;
      margin-bottom: 8px;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      position: static; } }
  .Home_vertical__XvcUw img {
    width: 60px; }

.Home_mobileBox__1RrEI {
  max-width: 480px; }
  @media (max-width: 768px) {
    .Home_mobileBox__1RrEI {
      padding: 0 0px 0 16px; } }
  @media (max-width: 520px) {
    .Home_mobileBox__1RrEI {
      max-width: 380px; } }
  @media (max-width: 460px) {
    .Home_mobileBox__1RrEI {
      max-width: 320px; } }
  @media (max-width: 340px) {
    .Home_mobileBox__1RrEI {
      max-width: 300px; } }

@media (max-width: 768px) {
  .Home_placesWrapper__3RMBM .container,
  .Home_populerEvents__2QnWF .container,
  .Home_specialEvents__2ehCu .container {
    padding-right: 0;
    padding-left: 0; }
    .Home_placesWrapper__3RMBM .container h3,
    .Home_populerEvents__2QnWF .container h3,
    .Home_specialEvents__2ehCu .container h3 {
      margin-left: 16px; } }

.Home_loader__Wvxkg {
  margin: 0px auto 24px;
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #ee2943;
  top: 50%;
  -webkit-animation: Home_loader__Wvxkg 2s infinite ease;
          animation: Home_loader__Wvxkg 2s infinite ease; }

.Home_loaderInner__1YYZc {
  display: block;
  width: 100%;
  background-color: #150f45;
  -webkit-animation: Home_loaderInner__1YYZc 2s infinite ease-in;
          animation: Home_loaderInner__1YYZc 2s infinite ease-in; }

@-webkit-keyframes Home_loader__Wvxkg {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes Home_loader__Wvxkg {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes Home_loaderInner__1YYZc {
  0% {
    height: 0%; }
  25% {
    height: 0%; }
  50% {
    height: 100%; }
  75% {
    height: 100%; }
  100% {
    height: 0%; } }

@keyframes Home_loaderInner__1YYZc {
  0% {
    height: 0%; }
  25% {
    height: 0%; }
  50% {
    height: 100%; }
  75% {
    height: 100%; }
  100% {
    height: 0%; } }

