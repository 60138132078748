.root.withMargin {
  margin-bottom: 32px;
}
.root {
  position: relative;
  
  a {
    display: block;

    &:last-of-type {
      display: flex;
      align-items: flex-end;
    }
  }

  img {
    max-width: 100%;
  }

  h1 {
    font-size: 19px;
    font-weight: 500;
    color: #00013a;
    margin: 24px 0 10px;
    line-height: 24px;
  }

  .place,
  .date {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.9px;
    color: #00013a;
  }

  .online{
    background-color: #ffffff;
    position: absolute;
    right: 24px;
    top: 23px;

    .title{
        padding: 5px 12px 4px 12px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 0.9px;
        text-align: left;
        color: #00013a;
    }
  }

  .bottom {
    margin-top: 16px;
    display: flex;
  }

  .buyButton {
    margin-left: 16px;
  }

  .priceWrapper {
    display: flex;
    align-items: flex-end;
  }

  button {
    text-transform: uppercase;
    font-size: 10px;
  }

  .price {
    margin-left: 16px;

    strong {
      font-size: 16px;
      font-weight: 500;
      color: #00013a;
      line-height: 1.31;
    }

    span {
      font-size: 10px;
      line-height: 1.31;
      color: #00013a;
    }

    > span {
      display: block;
    }
  }
}
